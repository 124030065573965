import React from 'react'
import Footer from "../Footer/Footer";

const Contact = () => {
  return (
    <div >
        <Footer />
    </div>  
    )
}

export default Contact